export default [
  {
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon',
  },

  // The Store
  {
    header: 'The Store', 
  },
  {
    title: 'Categories',
    route: 'categories',
    icon: 'ListIcon',
  },
  {
    title: 'Products',
    route: 'products',
    icon: 'CodesandboxIcon',
  },
  {
    title: 'Orders',
    route: 'orders',
    icon: 'LayersIcon',
  },
  {
    title: 'Orders Ratings',
    route: 'orders-ratings',
    icon: 'StarIcon',
  },
  {
    title: 'Shoppers',
    route: 'shoppers',
    icon: 'UsersIcon',
  },
  {
    title: 'Pages',
    route: 'pages',
    icon: 'FileIcon',
  },
  {
    title: 'Reports',
    route: 'reports',
    icon: 'PieChartIcon',
  },
  // {
  //   title: 'Online Payments',
  //   route: 'online-payments',
  //   icon: 'PieChartIcon',
  // },



  // Marketing Tools
  {
    header: 'Marketing Tools', 
  },
  {
    title: 'Marketing Offers',
    route: 'marketing-offers',
    icon: 'PercentIcon',
  },
  {
    title: 'Frontend Design',
    route: 'frontend-design',
    icon: 'FeatherIcon',
  },
  // Settings
  {
    header: 'Settings',
  },
  {
    title: 'Store Configurations',
    route: 'store-configurations',
    icon: 'SettingsIcon',
  },
  {
    title: 'Mhd Store',
    route: 'mhd-store',
    icon: 'FileIcon',
  },
  {
    title: 'Orders For Delivery',
    route: 'shipping-zones-show-orders',
    icon: 'LayersIcon',
  },
]
