<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <b-nav-item>
        <feather-icon
          size="18"
          icon="MailIcon"
        />
        {{smsBalance}} <span class="d-none d-sm-inline">{{$t('SMS Message')}}</span>
      </b-nav-item> -->
      <locale />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
            </p>
            <span class="user-status">{{$store.state.user.name || ''}}</span>
          </div>
          <b-avatar
            size="40"
            v-if="$store.state.store.logo"
            variant="light-primary"
            badge
            :src="$store.state.store.logo"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click="goToStore">
            <feather-icon
              size="16"
              icon="ExternalLinkIcon"
              class="mr-50"
            />
            <span>{{$t('Go To Store')}}</span>
        </b-dropdown-item>

         <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout()">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{$t('Logout')}}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from './Locale.vue'
import useJwt from '@/auth/useJwt'


export default {
  components: {
    // Navbar Components
    DarkToggler,
    Locale
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      smsBalance: 0,
    };
  },

  computed:{
      isLoggedIn:function(){
        return this.$store.state.isLoggedIn
      },
  },

  mounted() {
    // this.getSMSBalance();
  },

  methods: {
      logout (){
        useJwt.removeToken()
        useJwt.removeUserData()
        useJwt.removeStoreData()
        window.location.reload()
      },
      getSMSBalance() {
        useJwt.post('/getSMSBalance',{id: this.$store.state.store.id})
        .then(response => {
          this.smsBalance = response.data.data
        })
        .catch(error => {

        });
      },  
      goToStore(){
        window.open('https://'+this.$store.state.store.url, '_blank');
      }
  }
}
</script>
